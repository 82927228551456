<template>
    <div id="desktopRegisterAdvantagesContainer">
        <div class="decorationCircles" id="decorationCircle1"></div>
        <div class="decorationCircles" id="decorationCircle2">
            <div></div>
        </div>
        <div class="decorationCircles" id="decorationCircle3">
            <div></div>
        </div>
        <div class="decorationCircles" id="decorationCircle4"></div>
        <h3>{{ $t('BE_PART_OF') }}</h3>
        <h1>{{ $t('COMMUNITY') }}</h1>
        <div id="decorationDiv"></div>
        <p>
            {{
                $t('Testers_signup_dont_forget_invite_friends')
            }}
        </p>
        <div id="advantages">
            <div>
                <img src="@/assets/svg/registerAdvantages_1.svg" />
                <p>{{ $t('Testers_signup_win_rewards_and_money') }}</p>
            </div>
            <div>
                <img src="@/assets/svg/registerAdvantages_2.svg" />
                <p>{{ $t('Testers_signup_up_comunity_and_becoume_as_expert') }}</p>
            </div>
            <div>
                <img src="@/assets/svg/registerAdvantages_3.svg" />
                <p>
                    {{ $t('tester_advantages_message') }}
                </p>
            </div>
            <div>
                <img src="@/assets/svg/registerAdvantages_4.svg" />
                <p>
                    {{ $t('tester_advantages_message2') }}
                </p>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');
* {
    box-sizing: border-box;
}
#desktopRegisterAdvantagesContainer {
    border-top-width: 10px;
    border-top-style: solid;
    border-image: linear-gradient(90deg, #ff1564, #fd7328) 1;
    background: linear-gradient(149.15deg, #3e216c 0%, #895ad2 100.01%);
    position: relative;
    .decorationCircles {
        position: absolute;
        border-radius: 50%;
        opacity: 10%;
        pointer-events: none;
    }
    #decorationCircle1 {
        width: 287px;
        height: 287px;
        top: -125px;
        left: -76px;
        background: #fcfaff;
    }
    #decorationCircle2 {
        width: 327px;
        height: 327px;
        left: 138px;
        top: 110px;
        background: linear-gradient(70.59deg, #341466 5.34%, #895ad2 96.14%),
            linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.7) 0%,
                rgba(0, 0, 0, 0) 17.93%,
                rgba(0, 0, 0, 0.0628259) 59.07%,
                rgba(0, 0, 0, 0.534021) 101.26%
            ),
            linear-gradient(0deg, rgba(0, 0, 0, 0.49), rgba(0, 0, 0, 0.49));
        display: flex;
        justify-content: center;
        align-items: center;
        div {
            border-radius: 50%;
            width: 240px;
            height: 240px;
            background: linear-gradient(149.15deg, #3e216c 0%, #895ad2 100.01%);
        }
    }
    > h3 {
        font-family: 'Lato';
        font-size: 33px;
        font-weight: 400;
        color: #ffffff;
        margin: 0px;
        margin-top: 20px;
    }
    > h1 {
        font-family: 'Bariol';
        font-size: 63px;
        font-weight: 700;
        color: #ffffff;
        margin: 0px;
        margin-top: 5px;
    }
    #decorationDiv {
        background: linear-gradient(90deg, #ff1564 0%, #fd7328 100%);
        height: 8px;
        width: 100px;
        margin-top: 10px;
    }
    > p {
        font-family: 'Lato';
        font-size: 23px;
        font-weight: 400;
        color: #ffffff;
    }
    #advantages {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        row-gap: 10px;
        column-gap: 20px;
        margin-top: 100px;
        > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            > p {
                text-align: center;
                font-family: 'Bariol';
                font-size: 18px;
                font-weight: 700;
                color: #ffffff;
                max-width: 300px;
            }
        }
    }
}
</style>