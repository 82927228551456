<template>
    <div id="registerAdvantagesContainer">
        <div id="borderDecoration"></div>
        <div class="decorationCircles" id="decorationCircle1"></div>
        <div class="decorationCircles" id="decorationCircle2">
            <div></div>
        </div>
        <div class="decorationCircles" id="decorationCircle3">
            <div></div>
        </div>
        <div class="decorationCircles" id="decorationCircle4"></div>
        <h3>{{$t('BE_PART_OF')}}</h3>
        <h1>{{$t('COMMUNITY')}}</h1>
        <div id="decorationDiv"></div>
        <p>
            {{$t('Testers_signup_dont_forget_invite_friends')}}
        </p>
        <div id="advantages">
            <div>
                <img src="@/assets/svg/registerAdvantages_1.svg">
                <p>{{ $t('Testers_signup_win_rewards_and_money') }}</p>
            </div>
            <div>
                <img src="@/assets/svg/registerAdvantages_2.svg">
                <p>
                    {{ $t('Testers_signup_up_comunity_and_becoume_as_expert') }}
                </p>
            </div>
            <div>
                <img src="@/assets/svg/registerAdvantages_3.svg">
                <p>
                    {{ $t('Testers_signup_test_online_for_u') }}
                </p>
            </div>
            <div>
                <img src="@/assets/svg/registerAdvantages_4.svg">
                <p>{{ $t('tester_advantages_message2') }}</p>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');
*{
    box-sizing: border-box;
}
    #registerAdvantagesContainer{
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        
        background: linear-gradient(149.15deg, #3E216C 0%, #895AD2 100.01%);
        padding: 20px;
        position: relative;
        overflow-x: hidden;
        #borderDecoration{
            position: absolute;
            top: 0;
            left: 0;
            height: 10px;
            width: 100%;
            z-index: 3;
            background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
        }
        .decorationCircles{
            position: absolute;
            border-radius: 50%;
            opacity: 10%;
            pointer-events: none;
        }
        #decorationCircle1{
            width: 287px;
            height: 287px;
            top: -125px;
            left: -76px;
            background: #FCFAFF;
        }
        #decorationCircle2{
            width: 327px;
            height: 327px;
            left: 138px;
            top: 110px;
            background: linear-gradient(70.59deg, #341466 5.34%, #895AD2 96.14%),
                        linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 17.93%, rgba(0, 0, 0, 0.0628259) 59.07%, rgba(0, 0, 0, 0.534021) 101.26%),
                        linear-gradient(0deg, rgba(0, 0, 0, 0.49), rgba(0, 0, 0, 0.49));
            display: flex;
            justify-content: center;
            align-items: center;
            div{
                border-radius: 50%;
                width: 240px;
                height: 240px;
                background: linear-gradient(149.15deg, #3E216C 0%, #895AD2 100.01%);
            }

        }
        >h3{
            font-family: 'Lato';
            font-size: 21px;
            font-weight: 400;
            color: #FFFFFF;
            margin: 0px;
            margin-top: 20px;
        }
        >h1{
            font-family: 'Bariol';
            font-size: 41px;
            font-weight: 700;
            color: #FFFFFF;
            margin: 0px;
            margin-top: 5px;
        }
        #decorationDiv{
          background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
          height: 4px;
          width: 60px;
          margin-top: 10px;
        }
        >p{
            font-family: 'Lato';
            font-size: 15px;
            font-weight: 400;
            color: #FFFFFF;
        }
        #advantages{
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            margin-top: 60px;
            >div{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                >p{
                    text-align: center;
                    font-family: 'Bariol';
                    font-size: 12px;
                    font-weight: 700;
                    color: #FFFFFF;
                }
            }
        }
    }
</style>